import { Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/load-local-document/load-local-document.component').then((m) => m.LoadLocalDocumentComponent),
  },
  {
    path: 'documents',
    pathMatch: 'full',
    canActivate: [authGuard],
    loadComponent: () => import('./pages/documents/documents.component').then((m) => m.DocumentsComponent),
  },
  {
    path: 'document/:documentCode',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./pages/document-details/document-details.component').then((m) => m.DocumentDetailsComponent),
  },
  {
    path: 'document/:documentCode/solicitation',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./pages/sign-solicitation/sign-solicitation.component').then((m) => m.SignSolicitationComponent),
  },
  {
    path: 'document/:documentCode/sign',
    canActivate: [authGuard],
    loadComponent: () => import('./pages/sign/sign.component').then((m) => m.SignComponent),
    loadChildren: () => import('./pages/sign/sign.routes').then((m) => m.signRoutes),
  },
  {
    path: 'login-callback',
    loadComponent: () =>
      import('./pages/login-callback/login-callback.component').then((m) => m.LoginCallbackComponent),
  },
];

export default class DevSettings {
  documentStorageUrl = {
    v1: 'https://alt-develop.api.aprova.com.br/document-storage-api/v1',
  };

  remoteSignerUrl = {
    v1: 'https://backend.develop.aprova.com.br/document-signer-api',
  };

  webpki = {
    apiKey:
      'AiEAKi5hcHJvdmEuY29tLmJyLCoucHJlZmVpdHVyYXMubmV0QwBpcDQ6MTAuMC4wLjAvOCxpcDQ6MTI3LjAuMC4wLzgsaXA0OjE3Mi4xNi4wLjAvMTIsaXA0OjE5Mi4xNjguMC4wLzE2AwBQcm8AAAABOllvzNrFPUw4wm6rxLyvjD29Kx91wsF0BZRnAM52cdw23Sbn9VkenhbceiHRK+rXfVeQOKZXy0Rsq5sK47CAdSyfOYyV3YomgHeYTN3GT6uzhCcG+QX00HVFrh4aajLbp3eC74Z/HK8kAb9j0gFRM7SiEtRWiKFMNg5fQkmT6LoeBP//inKwePScYJfc3srB2Txycbvli6I02MSBOmwLNERA0xXYnJDte4eT5wT96YHBS5HOIfURRl0tw7DbC/TLqJRQVPxruIjuafbnk5/uMKoni2oHP8Ftk/kpcuts3WryaB9DN6hinbEhSCoH5E8D+j9fxGVb5A4ZYz/KM5Vqxw==',
    pluginUrl: 'https://chrome.google.com/webstore/detail/web-pki/dcngeagmmhegagicpcmpinaoklddcgon',
  };

  loginUrl = 'https://develop-app.aprova.com.br/';

  authenticationUrl = {
    v1: 'https://alt-develop.api.aprova.com.br/authentication-api/v1',
  };

  documentRenderUrl = 'https://backend.develop.aprova.com.br/document-render-api';

  signManagerUrl = 'https://alt-develop.api.aprova.com.br/sign-manager-api';

  helpDeskUrl = 'https://aprova.movidesk.com/kb/';

  feedbackFormUrl = 'https://form.jotform.com/223632962524053';

  certificatesUrl = 'https://backend.develop.aprova.com.br/certificates-api';

  actorApiUrl = 'https://alt-develop.api.aprova.com.br/actor-api';

  usersUrl = 'https://alt-develop.api.aprova.com.br/users';

  ciUrl = 'https://alt-develop.api.aprova.com.br/ci';
}

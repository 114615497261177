import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetMyPermissionsServiceInterface } from '../interfaces/services/get-my-permissions.service.interface';
import { UserPermissionsDto } from '../dtos/user-permissions.dto';
import { Types } from '../../types.domain';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class GetMyPermissionsService implements GetMyPermissionsServiceInterface {
  private readonly _http = inject(HttpClient);
  private readonly _settings = inject(Types.Settings);

  async searchMyPermissions(): Promise<UserPermissionsDto> {
    const requestUrl = `${this._settings.usersUrl}/v2/my/permissions`;
    return lastValueFrom(this._http.get<UserPermissionsDto>(requestUrl));
  }
}

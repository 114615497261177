import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CreateSolicitationsInput } from '../dtos/create-solicitations.input';
import { Solicitation } from '../entities/solicitation';
import { CreateSolicitationsServiceInterface } from '../interfaces/create-solicitations.service.interface';
import { Types } from '../../types.domain';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class CreateSolicitationsService implements CreateSolicitationsServiceInterface {
  private readonly _http = inject(HttpClient);
  private readonly _settings = inject(Types.Settings);

  async addSolicitations(solicitation: CreateSolicitationsInput): Promise<Solicitation> {
    const requestUrl = `${this._settings.signManagerUrl}/solicitations/v1/bulk`;
    return lastValueFrom(this._http.post<Solicitation>(requestUrl, solicitation));
  }
}

import { ClassProvider } from '@angular/core';

import { GetMyPermissionsService } from '../../../domain/user/services/get-my-permissions.service';
import { Types } from '../../../domain/types.domain';

export class GetMyPermissionsServiceInstruction implements ClassProvider {
  provide = Types.GetMyPermissions;
  deps = [Types.Settings];
  useClass = GetMyPermissionsService;
}

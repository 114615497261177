import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, switchMap, from } from 'rxjs';
import { Types } from '../../types.domain';
import { ActorApiServiceInterface } from '../interfaces/services/actor-api.interface';
import { Favorite } from '../dtos/favorite.dto';
import { GetActorsTreeOutput } from '../dtos/get-actors-tree.output';

@Injectable()
export class ActorApiService implements ActorApiServiceInterface {
  private readonly _httpClient = inject(HttpClient);
  private readonly _settings = inject(Types.Settings);
  private readonly _getCurrentUserService = inject(Types.GetCurrentUserService);

  getActorsTree(
    page: number,
    perPage: number,
    filterBy: string,
    favorites: string[] = [],
    text: string = ''
  ): Observable<GetActorsTreeOutput> {
    const favoritesQueryParam = favorites.join(',');
    return from(this._getCurrentUserService.handle()).pipe(
      switchMap((user) =>
        this._httpClient.get<GetActorsTreeOutput>(`${this._settings.actorApiUrl}/etl/list/tree`, {
          params: {
            page: page.toString(),
            pageSize: perPage.toString(),
            organizationId: user.cityIndex.toString(),
            textSearch: text,
            filterBy,
            favorites: favoritesQueryParam,
          },
        })
      )
    );
  }

  public getFavorites(): Observable<Favorite[]> {
    return this._httpClient.get<Favorite[]>(`${this._settings.ciUrl}/favorites/simple`);
  }

  public createFavorite(
    favorite: Pick<Favorite, 'entityType' | 'entityReference'>
  ): Observable<Favorite> {
    return this._httpClient.post<Favorite>(`${this._settings.ciUrl}/favorites`, favorite);
  }

  public deleteFavorite(favoriteId: string): Observable<void> {
    return this._httpClient.delete<void>(`${this._settings.ciUrl}/favorites/${favoriteId}`);
  }
}
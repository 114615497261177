import { ClassProvider } from '@angular/core';

import { CreateSolicitationsService } from '../../../domain/solicitations/services/create-solicitations.service';
import { Types } from '../../../domain/types.domain';

export class CreateSolicitationServiceInstruction implements ClassProvider {
  provide = Types.CreateSolicitations;
  deps = [Types.Settings];
  useClass = CreateSolicitationsService;
}
